@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.App {
  display:flex;
  background-color: #f4f4ff;
  color:white;
  position:absolute;
  top:0;bottom:0;right:0;left:0;
  
}

.sidemenu {
  display: flex;
  flex-direction: column;
  flex: 0 0 260px;
  width:260px;
  padding:8px;
  background-color:#F0F0F1;
}
/* under 640px do this */
@media (max-width: 640px) {
  .sidemenu {
    display:none;
  }
  }

.expand-button {
  display:none;
  position:absolute;
  top:0;bottom:0;right:0;left:0;
  background-color:black (0,0,0,0.5);
  z-index:100;
}

.side-menu-button { 
  padding:12px;
  border:1px solid black;
  border-radius:5px;
  text-align: left;
  transition:ease 0.25s all;
  cursor:pointer;
  background-color:black;
  color:white;
 }



.side-menu-button:hover {
  background-color:#61dafb;
}
.side-menu-button span {
  padding-left:6px;
  padding-right:12px;
}

.side-menu-container {
  
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  position:relative;
vertical-align: top;
  scrollbar-color: #61dafb #343541;
  scrollbar-base-color: #444654;
  overflow-y:scroll;
  scrollbar-width: thick;
   overflow: auto;
  
}
 
.side-menu-container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.side-menu-container::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 5px;
}

.side-menu-container::-webkit-scrollbar-track {
  background-color: #444654;
  border-radius: 5px;
}

.dropdown {
  padding:5px;
  display: inline-block;
  position: relative;
  cursor:pointer;
  background-color:black;
  color:white;
  border:0px solid black;
  border-radius:9px;
  text-align: left;
  transition:ease 1s all;
  cursor:pointer;
  }

  .checkbox-group {
    padding: 5px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: black;
    color: white;
    border: 0px solid black;
    border-radius: 9px;
    text-align: left;
    transition: ease 1s all;
    cursor: pointer;
    display:block
  }
  

.Main-logo {
 
  position:relative;
  margin-bottom: 10px;
  flex-direction: row;
  flex: 1 1 auto;
  
  left: 45px;

  }

  .switchtext {
  align-self: flex-end;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  align-items: right;
  opacity:0.66;
  font-size:.8em;
  bottom: 4px;
  left: 90px;
  color: black;
  text-align: left;
  padding: 5px;
  }

.switch {
  
  align-self: flex-end;
  flex-direction: row;
  position: absolute;
  bottom: 5px;
  left: 200px;
  align-self: flex-end;
  width: 45px;
  height: 25px;
  
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #61dafb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:focus + .slider {
  box-shadow: 0 0 1px #61dafb;
}

.chatbox {
  flex:1 1 auto;
  background-color:#343541;
  position:relative;
  overflow-y:scroll;
  scrollbar-color: #61dafb #343541;
  scrollbar-base-color: #444654;
  padding-bottom:95px;
  
}

@keyframes chatbox-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.chatbox--loading::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #61dafb;
  border-radius: 50%;
  animation: chatbox-loading-spinner 1s ease infinite;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-holder {
  padding:12px;
  position:absolute;
  bottom:0;
  left:0;right:0;
  display: 'flex';
  flex-direction: column;
  flex: 1 5 auto;
  flex-wrap: wrap;

  
}
.chat-input-textarea {
  background-color:#40414f;
  width:20%;
  padding:12px;
  border-radius:7px;
  color:white;
  font-size:1em;
  border:none;
  outline:none;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
  overflow: 'auto';
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 5 5 auto;  
  max-height: 400px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
  position: absolute;
  top:0;right:0;bottom:89px;left:0;
  overflow-y:scroll;
  scrollbar-color: #61dafb #343541;
  scrollbar-base-color: #444654;
  text-align:left;
  
}

.chat-message.AIFORCE {
  background-color:#444654;
}
.chat-message-center {
  max-width:900px;
  /* margin-left:auto;
  margin-right:auto; */
  display:flex;
  padding:12px;
  padding-left: 24px;
  padding-right: 24px;
  }

  .copy-button {
    position: absolute;
    padding: 2px 3px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    left: 75%;
    transform: translateY(40%);
    
  }
  
  .copy-button.clicked::after {
    content: "Copied!";
    font-weight: 400;
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .copy-button:hover {
    background:#f4f4ff;
  }

  .avatar {
  background:white;
  border-radius:50%;
  width: 40px;
  height: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px;
  font-size:14px;
  color: black;
}
.avatar.AIFORCE {
  background:#444654;
  border-radius:50%;
  min-width: 40px;
  min-height: 40px;
  padding:5px;
  color:black;
  }
  .aiforce_logo {
  width: 40px;
  background: 444654;
  border-radius:0%;
  min-width: 40px;
  min-height: 40px;
  padding:3px;
  }

.message {
  padding-left: 20px;
  padding-right: 40px;
  letter-spacing: 0px;
  inline-size: 700px;
  
  
}

pre {
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-family: 'Roboto', sans-serif;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-models {
  border: 1px solid black;
  padding:12px;
  border-radius:5px;
  color:white;
  background:black;
  outline:none;
  cursor:pointer;
  max-width:100%;
  min-width:100%;
}
.select-models option {
  background:white;
  color:black;
}

.button-picker {
  border:none;
  padding:6px 12px ;
  border-radius:5px;
  color:black;
  background:white;
  margin-top:6px;
  cursor: pointer;
}
.button-picker:hover {
  background:#61dafb;
}

.info {
  align-self: flex-end;
  display: inline-flex;
  flex-direction: column;
  align-items: right;
  opacity:0.66;
  font-size:1em;
  margin-top:2px;
  margin-left: 10px;
  color: black;
  text-align: left;
  
}

.side-label {
  font-size:0.9em;
  margin-top:5em;
  margin-bottom:0.5em;
  padding-bottom:0em;
  display:block;
  color: black;
}

.form {
  display:flex;
  gap:6px;

}

.retry {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background:#0000FF;
  display:flex-end;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
  font-size:16px;
  color:white;
  border-radius:5px;
  cursor: pointer;
  flex: 1 1 auto;
}

.stepback {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background:#0000FF;
  display:flex-end;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
  font-size:16px;
  color:white;
  border-radius:5px;
  cursor: pointer;
  flex: 1 1 auto;
}

message-response {
  resize: none;
  overflow: hidden;
  max-height: 800px;
  width: 100%;
  
}

.submit {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background:#0000FF;
  display:flex-end;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
  font-size:16px;
  color:white;
  border-radius:5px;
  cursor: pointer;
  flex: 1 1 auto;
  font-size: 10px;
}
.submit:hover {
  background:#010181;
}

.submitdisabled {
  position: relative;
  display:flex-end;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
	font: inherit;
  border: none;
  background:#343541;
  cursor:default;
  color: white;
  font-size: 10px;
  border-radius:5px;
  flex: 1 1 auto;
    }

 
.dot-falling {
  top: 0px;
  margin: auto;
  margin-top: 5px;
  position: relative;
  display: inline block;
  right: 9999px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #61dafb;
  color: #61dafb;
  box-shadow: 9999px 0 0 0 #61dafb;
  animation: dot-falling 1s infinite linear;
  animation-delay: 2.1s;
}
.dot-falling::before, .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #61dafb;
  color: #61dafb;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #61dafb;
  color: #61dafb;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #61dafb;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #61dafb;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #61dafb;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}